// InstagramIconFooter.jsx

import React from 'react';
import instagramIconFooter from 'images/forinstafooter.svg';
// import styles from 'components/Logo/Logo.module.css';


export const InstagramIconFooter = () => {
 return <img  src={instagramIconFooter} alt="instagramIconFooter"  />;
};

export default InstagramIconFooter;