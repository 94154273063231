// MailIconFooter.jsx

import React from 'react';
import mailIconFooter from 'images/formailfooter.svg';
// import styles from 'components/Logo/Logo.module.css';


export const MailIconFooter = () => {
 return <img src={mailIconFooter} alt="mailIconFooter"  />;
};

export default MailIconFooter;