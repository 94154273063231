// BlackberryPhoneIcon.jsx

import React from 'react';
import blackberryPhoneIcon from 'images/mobile.svg';


export const BlackberryPhoneIcon = () => {
 return <img src={blackberryPhoneIcon} alt="blackberryPhoneIcon"  />;
};

export default BlackberryPhoneIcon;