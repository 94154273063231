// FacebookIconFooter.jsx

import React from 'react';
import facebookIconFooter from 'images/forfacebookfooter.svg';


export const FacebookIconFooter = () => {
 return <img src={facebookIconFooter} alt="facebookIconFooter"  />;
};

export default FacebookIconFooter;